import '@rainbow-me/rainbowkit/styles.css'
import '@/styles/base.css'
import '@/styles/font.css'
import Script from 'next/script'
import Head from 'next/head'
import App from 'next/app'
import { CacheProvider } from '@emotion/react'
import createEmotionCache from '@/utils/createEmotionCache'
import { StyledEngineProvider } from '@mui/material/styles'
import { I18nProvider } from '@lingui/react'
import { i18n } from '@lingui/core'
import { useEffect } from 'react'
import { activate, useLinguiInit } from '@/utils/i18n'
import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { connectorsForWallets } from '@rainbow-me/rainbowkit'
import {
  injectedWallet,
  metaMaskWallet,
  coinbaseWallet,
  rainbowWallet,
  walletConnectWallet,
  okxWallet,
} from '@rainbow-me/rainbowkit/wallets'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiProvider, http, fallback } from 'wagmi'
import { getDefaultConfig } from '@rainbow-me/rainbowkit'
import { config } from '@/utils/getDefaultConfig'

// import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { mainnet, goerli, bsc, bscTestnet, zkSync, sepolia, polygon } from 'wagmi/chains'
// import { infuraProvider } from 'wagmi/providers/infura'
// import { alchemyProvider } from 'wagmi/providers/alchemy'
// import { publicProvider } from 'wagmi/providers/public'
import { v4 } from 'uuid'
import { addNativeReceiver } from '@/utils/handleNativeMessage'

const isServer = () => typeof window === 'undefined'
const clientSideEmotionCache = createEmotionCache()
const isProductionEnv = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
if (!process.env.NEXT_PUBLIC_PROJECT_ID) {
  throw new Error('You need to provide NEXT_PUBLIC_PROJECT_ID env variable')
}

const projectId = process.env.NEXT_PUBLIC_PROJECT_ID

// const bsc = {
//   id: 56,
//   name: 'Binance Smart Chain Mainnet',
//   network: 'BSC',
//   nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
//   rpcUrls: {
//     default: 'https://bsc-dataseed1.binance.org',
//     public: 'https://bsc-dataseed1.binance.org',
//   },
//   blockExplorers: {
//     etherscan: {
//       name: 'BscScan',
//       url: 'https://bscscan.com',
//     },
//     default: {
//       name: 'BscScan',
//       url: 'https://bscscan.com',
//     },
//   },
// }

// var bsc = {
//   id: 56,
//   name: 'Binance Smart Chain',
//   network: 'bsc',
//   nativeCurrency: {
//     decimals: 18,
//     name: 'BNB',
//     symbol: 'BNB',
//   },
//   rpcUrls: {
//     default: { http: ['https://bsc-dataseed.binance.org'] },
//   },
//   blockExplorers: {
//     etherscan: { name: 'BscScan', url: 'https://bscscan.com' },
//     default: { name: 'BscScan', url: 'https://bscscan.com' },
//   },
//   // contracts: {
//   //   multicall3: {
//   //     address: '0xca11bde05977b3631167028862be2a173976ca11' as `0x${string}`,
//   //     blockCreated: 15921452,
//   //   },
//   // },
// }

// var bscTestnet = {
//   id: 97,
//   name: 'Binance Smart Chain Testnet',
//   network: 'bsc-testnet',
//   nativeCurrency: {
//     decimals: 18,
//     name: 'BNB',
//     symbol: 'tBNB',
//   },
//   rpcUrls: {
//     default: { http: ['https://data-seed-prebsc-2-s3.binance.org:8545'] },
//   },
//   blockExplorers: {
//     etherscan: { name: 'BscScan', url: 'https://testnet.bscscan.com' },
//     default: { name: 'BscScan', url: 'https://testnet.bscscan.com' },
//   },
//   contracts: {
//     multicall3: {
//       address: '0xca11bde05977b3631167028862be2a173976ca11' as `0x${string}`,
//       blockCreated: 17422483,
//     },
//   },
//   testnet: true,
// }

// const { chains, publicClient } = configureChains(
//   [mainnet, goerli, bsc, bscTestnet, zkSync, sepolia],
//   [
//     infuraProvider({ apiKey: '2154cb9483024ecea38c9dcee79f3603' }),
//     alchemyProvider({ apiKey: 'A8_DFKZzEjpNp4olgy271VdhjL9n0xGe' }),
//     // w3mProvider({ projectId }),
//     publicProvider(),
//   ],
// )

// const connectors = connectorsForWallets([
//   {
//     groupName: 'Recommended',
//     wallets: [
//       injectedWallet({ chains }),
//       metaMaskWallet({ projectId, chains, walletConnectVersion: '2' }),
//       coinbaseWallet({ appName: 'RNSID', chains }),
//       rainbowWallet({ projectId, chains, walletConnectVersion: '2' }),
//       okxWallet({ projectId, chains }),
//       // trustWallet({ projectId, chains }),
//       walletConnectWallet({ projectId, chains, version: '2' }),
//     ],
//   },
// ])

// const config = createConfig({
//   autoConnect: true,
//   connectors,
//   publicClient,
// })

// const config = createConfig({
//   chains: [mainnet, sepolia],
//   transports: {
//     [mainnet.id]: http(),
//     [sepolia.id]: http(),
//   },
// })

// const config = getDefaultConfig({
//   appName: 'RNSID',
//   projectId: projectId,
//   chains: [mainnet, bsc, bscTestnet, sepolia],
//   transports: {
//     [mainnet.id]: fallback([
//       http(),
//       http('https://mainnet.infura.io/v3/2154cb9483024ecea38c9dcee79f3603'),
//       http('https://eth-mainnet.g.alchemy.com/v2/A8_DFKZzEjpNp4olgy271VdhjL9n0xGe'),
//     ]),
//     [polygon.id]: fallback([http(), http('https://polygon-mainnet.infura.io/v3/2154cb9483024ecea38c9dcee79f3603')]),
//     [bsc.id]: fallback([http(), http('https://bsc-dataseed1.ninicoin.io	'), http('https://bsc-dataseed1.defibit.io')]),
//     [bscTestnet.id]: fallback([http()]),
//     [sepolia.id]: fallback([http()]),
//   },
// })

const queryClient = new QueryClient()

// export const ethereumClient = new EthereumClient(wagmiConfig, [mainnet, goerli, bsc, bscTestnet])

function MyApp({ Component, pageProps, emotionCache = clientSideEmotionCache }) {
  useLinguiInit(pageProps.translation)

  useEffect(() => {
    // Activate the default locale on page load
    // activate(window.localStorage.getItem('rns-lng') || 'en')
    addNativeReceiver()
    if (window.location.host === 'www.rns.id') {
      const href = window.location.href.replace('www.rns.id', 'rns.id')
      window.location.replace(href)
    }

    //hotjar不在这些页面加载，和支付存在冲突
    // const forbiddenHotjar = [
    //   '/app/checkout',
    //   '/app/checkoutForRentals',
    //   '/app/checkoutForFlight',
    //   '/app/checkoutForHotel',
    //   '/app/applyReship',
    // ]
    // if (!forbiddenHotjar.includes(window.location.pathname)) {
    //   loadHotjar(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
    // }
    const linkTag = document.createElement('link')
    linkTag.setAttribute('rel', 'canonical')
    linkTag.href = window.location.origin + window.location.pathname
    document.head.appendChild(linkTag)
  }, [])

  if (!isServer()) {
    import('@/utils/adapt')
    try {
      if (!crypto.randomUUID) {
        crypto.randomUUID = v4 as any
      }
    } catch (error) {}
  }

  // const loadHotjar = (h, o, t, j, a?, r?) => {
  //   //@ts-ignore
  //   h.hj =
  //     //@ts-ignore
  //     h.hj ||
  //     function () {
  //       //@ts-ignore
  //       ;(h.hj.q = h.hj.q || []).push(arguments)
  //     }
  //   //@ts-ignore
  //   h._hjSettings = { hjid: 3374438, hjsv: 6 }
  //   a = o.getElementsByTagName('head')[0]
  //   r = o.createElement('script')
  //   //@ts-ignore
  //   r.async = 1
  //   //@ts-ignore
  //   r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
  //   a.appendChild(r)
  // }

  return (
    <StyledEngineProvider injectFirst>
      <CacheProvider value={emotionCache}>
        <Head>
          <title>rns.id - Web 3.0 Digital ID</title>
          <meta name="viewport" content="initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no" />
          <meta httpEquiv="Cache-Control" content="public, max-age=3600" />
        </Head>
        {isProductionEnv && (
          <Script
            async
            src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCZdvh0vEkcQJPmwnZu1zDpaWOF0l4_VNM&libraries=places"
          />
        )}
        {isProductionEnv && (
          <Script
            dangerouslySetInnerHTML={{
              __html: `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
              },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
              a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
              twq('config','oee0f');`,
            }}
          />
        )}

        {isProductionEnv && (
          <Script
            dangerouslySetInnerHTML={{
              __html: `!function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '2189712404728755');
              fbq('track', 'PageView');`,
            }}
          />
        )}
        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        {isProductionEnv && <Script src="https://www.googletagmanager.com/gtag/js?id=G-K6M48X3BL0" />}
        {isProductionEnv && (
          <Script
            id="ga-analytics"
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-K6M48X3BL0', {
              page_path: window.location.pathname,
            });

            gtag('config', 'AW-10839746782');

            function getUrlParams (url) {
              var params = {}
              if (!url || url.indexOf('?') == -1) {
                return params
              }
              var index = url.indexOf('?')
              var pms = url.substring(index + 1)
              var as = pms.split('&')
              for (var i = 0; i < as.length; i++) {
                var index = as[i].indexOf('=')
                if (index !== -1) {
                  try {
                    params[as[i].substring(0, index)] = decodeURIComponent(as[i].substring(index + 1))
                  } catch (err) {
                    params[as[i].substring(0, index)] = as[i].substring(index + 1)
                  }
                }
              }
              return params
            }
            var urlParams = getUrlParams(window.location.search)
            if(urlParams.from){
              gtag('event', 'social_media_tracking', {
                media_name: urlParams.from
              })
            }
            `,
            }}
          />
        )}

        {isProductionEnv && (
          <Script
            dangerouslySetInnerHTML={{
              __html: `var _czc = _czc || [];
              (function () {
                var um = document.createElement("script");
                um.src = "https://s4.cnzz.com/z.js?id=1281364334&async=1";
                var s = document.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(um, s);
              })();`,
            }}
          ></Script>
        )}

        {isProductionEnv && (
          <Script
            id="live-chat"
            dangerouslySetInnerHTML={{
              __html: `
            window.__lc = window.__lc || {};
            window.__lc.license = 14250873;
            window.__lc.integration_name = "manual_channels";
            window.__lc.product_name = "livechat";
            ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))        
            `,
            }}
          />
        )}

        {/* {!isProductionEnv && (
          <>
            <Script src="https://unpkg.com/vconsole@latest/dist/vconsole.min.js" />
            <Script
              type="text/javascript"
              id="vconsole"
              dangerouslySetInnerHTML={{
                __html: `
            (function(){
              setTimeout(function(){ 
                if(window && window.VConsole) {
                  var vConsole = new window.VConsole();
                  vConsole.setSwitchPosition(100, 100);
                }
                console.log('window.VConsole added')
            }, 2000);})()    
            `,
              }}
            />
          </>
        )} */}
        <Script
          id="j-hotjar"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `(function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:3374438,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
          }}
        />
        <I18nProvider i18n={i18n}>
          <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
              <RainbowKitProvider modalSize="compact">
                <Component {...pageProps} />
              </RainbowKitProvider>
            </QueryClientProvider>
          </WagmiProvider>
        </I18nProvider>
        {/* <Web3Modal
          //@ts-ignore
          themeZIndex={1999}
          projectId={projectId}
          ethereumClient={ethereumClient}
          enableNetworkView={false}
          // mobileWallets={[
          //   {
          //     id: 'metaMask',
          //     name: 'MetaMask Wallet',
          //     links: { native: 'metamask:', universal: 'https://metamask.app.link' },
          //   },
          //   // {
          //   //   id: 'trust',
          //   //   name: 'Trust Wallet',
          //   //   links: { native: 'trust://', universal: 'https://link.trustwallet.com' },
          //   // },
          // ]}
          // Custom Linking Desktop Wallets
          // desktopWallets={
          //   [
          //     // {
          //     //   id: 'trust',
          //     //   name: 'Trust Wallet',
          //     //   links: { native: 'trust://', universal: 'https://link.trustwallet.com' },
          //     // },
          //   ]
          // }
        /> */}
      </CacheProvider>
    </StyledEngineProvider>
  )
}

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext)
  const { ctx } = appContext
  if (ctx.res) {
    ctx.res.setHeader('X-Frame-Options', 'SAMEORIGIN')
    ctx.res.setHeader('Strict-Transport-Security', 'max-age=3600; includeSubDomains; preload')
  }
  return { ...appProps }
}

export default MyApp
